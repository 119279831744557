@import "~@/styles/variables/variables.scss";









































































































































































































::v-deep .el-form-item {
	.el-upload {
		display: block;
		.el-upload-dragger {
			width: 100%;
			height: 142px;
			.el-icon-upload {
				font-size: 46px;
				margin: 30px 0 0;
			}
			.el-upload__text {
				font-size: 12px;
				line-height: 1;
			}
			.filename {
				margin-top: 52px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding: 0 12px;
			}
		}
	}
	.el-upload__tip {
		margin-top: 0;
	}
}
